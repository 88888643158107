<template>
  <div class="card" v-if="state.showDeveloperAccount">
    <h4>HELP DOCS</h4>
    <ul>
      <li>
        How to create Firebase project and invite Figmobi to it?
      </li>
      <li>
        How to create your Google developer account and invite Figmobi to it?
      </li>
      <li>
        How to create your Apple Developer Account and invite Figmobi to it?
      </li>
    </ul>
  </div>
</template>

<script setup>
import useGlobal from '../../composables/useGlobal'
import { reactive } from 'vue'

const { emitter } = useGlobal()

const state = reactive({
  showDeveloperAccount: false,
})

emitter.on('publish-app-active-tab', (tab) => {
  if (tab === '3') {
    state.showDeveloperAccount = true
  } else {
    state.showDeveloperAccount = false
  }
})
</script>

<style scoped>
.card {
  margin-top: 1.5em;
}
</style>
