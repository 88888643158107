<template>
  <div class="card">
    <div class="container-fluid">
      <h4>{{ t('storeListed') }}</h4>
      <div class="row">
        <div class="col-lg-6">
          <div class="input-wrap">
            <label> {{ t('appName') }}
              <input class="form-control" type="text">
            </label>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="input-wrap">
            <label> {{ t('shortDescription') }}
              <input class="form-control" type="text">
            </label>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="input-wrap">
            <label> {{ t('longDescription') }}
              <textarea class="form-control" name="longDescription" id="" cols="30" rows="3"></textarea>
            </label>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="input-wrap">
            <label> {{ t('keywords') }}
              <input class="form-control" type="text">
            </label>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="input-wrap">
            <label> {{ t('supportPhoneNumber') }}
              <input class="form-control" type="text">
            </label>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="input-wrap">
            <label> {{ t('supportEmail') }}
              <input class="form-control" type="text">
            </label>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="input-wrap">
            <label> {{ t('privacyPolicyUrl') }}
              <input class="form-control" type="text">
            </label>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="input-wrap">
            <label class="check-wrap">
              <input type="checkbox" id="acceptTermsContidions">
              {{ t('iAccept') }}
              <a target="_blank" href="#">{{ t('TermsContidions') }}</a>
            </label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6"></div>
        <div class="col-lg-6">
          <div class="input-wrap">
            <a @click="onSubmit" class="btn btn-success">{{ t('save') }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script setup>
import useGlobal from '../../composables/useGlobal'
import { defineEmits } from 'vue'

const emit = defineEmits()

const { t } = useGlobal()

const onSubmit = () => {
  emit('success')
}


</script>
