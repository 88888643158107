<template>
  <div class="container-fluid">

    <div class="row pt-3">
      <div class="col-lg-3"></div>
      <div v-for="item in pricingOptions" :key="item.title" class="col-lg-3">
        <div class="box">
          <div class="icon">
            <img :src="require(`../../assets/img/pricing/${item.image}.svg`)"/>
          </div>
          <h3>{{ item.title }}</h3>
          <div class="price">
            <span>{{ item.price }}</span>
            <span class="period">/ {{ item.pricePeriod }}</span>
          </div>
          <ul>
            <li v-for="feature in item.features" :key="feature">
              {{ feature }}
            </li>
          </ul>
          <button
            @click="subscription"
            :disabled="item.buttonDisabled"
            class="btn btn-outline-primary"
          >
            Get Started
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {reactive, defineProps} from 'vue'
import useGlobal from '../../composables/useGlobal'
import {redirect} from "@shopify/app-bridge/client/redirect";

const {t, restApi} = useGlobal()
const props = defineProps(
  {
    isActive: Boolean,
  },
)
const subscription = () => {

  return restApi.subscription(shop).then(confirmationUrl => {

    window.open(confirmationUrl, '_blank')
  })

}
const pricingOptions = reactive([
  {
    title: 'Basic',
    price: '$79',
    pricePeriod: 'mo',
    image: '1',
    features: [
      'Native IOS & Android Apps',
      'Unlimited Push Notifications',
      'Multi Language',
    ],
    buttonDisabled: false,
  },
  // {
  //   title: 'Business',
  //   price: '$159',
  //   pricePeriod: 'mo',
  //   image: '2',
  //
  //   features: [
  //     'Scheduled Push Notifications',
  //     'App-only Discounts',
  //     'Multi Currency',
  //     'Deeplink',
  //     'Google & FB ADs SDKs',
  //   ],
  //   buttonDisabled: true,
  // },
  // {
  //   title: 'Business+',
  //   price: '$289',
  //   pricePeriod: 'mo',
  //   image: '3',
  //
  //   features: [
  //     'Advenced Push Notifications',
  //     'Abondoned Cart Campaigns',
  //     'Advance Analytics',
  //     'Multiple Login Options',
  //     'Product Recommendation',
  //   ],
  //   buttonDisabled: true,
  // },
  // {
  //   title: 'Enterprice',
  //   price: '$819',
  //   pricePeriod: 'mo',
  //   image: '4',
  //
  //   features: [
  //     'Multiple Channels',
  //     'Custom Features',
  //     'Comments',
  //     'Analytics',
  //     '7*24 Call and Email Support',
  //   ],
  //   buttonDisabled: true,
  // },
])
</script>


<style scoped>
ul {
  margin: 0;
  padding-left: 0;
  list-style: none;
  text-align: left;
}

.box {
  padding: 1em;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 2px 6px 10px rgba(0, 0, 0, 0.0678079);
  text-align: center;
  display: grid;
  grid-template-rows: auto auto auto 1fr auto;
  row-gap: 1em;
  min-height: 500px;
  transition: all 0.5s;
  margin-bottom: 2em;
}

.box:hover {
  box-shadow: 0px 6px 23px 12px #dceaf5;
  transition: all 0.5s;
  transform: translateY(-8px);
}

.box img {
  width: 120px;
}

.price {
  font-size: 1.5em;
  font-weight: 900;
  color: #ff8b57;
}

.btn {
  border-radius: 10px;
}

.btn:hover {
  background-color: #00a79d !important;
  color: #fff !important;
}

.period {
  font-weight: 100;
  font-size: 0.8em;
}

.pricing {
  position: relative;
}
</style>
