<template>
  <MemberLayout>
    <div class="page">
      <div class="nav">
        <span style="margin-right: auto">{{ t('publishApp') }}</span>
      </div>
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-9">
            <div class="tabs">
              <a-tabs v-model:activeKey="state.activeTab" type="card">
                <a-tab-pane key="1" :tab="t('pricing')">

                  <Pricing/>

                </a-tab-pane>

                <a-tab-pane key="2" :tab="t('appInfos')">

                  <AppInfos/>

                </a-tab-pane>

                <a-tab-pane key="3" :tab="t('developerAccount')">

                  <DeveloperAccount/>

                </a-tab-pane>
              </a-tabs>
            </div>
          </div>
          <div class="col-lg-3">

            <div class="complete-status">
              <h5>{{ t('completeStatus') }}</h5>
              <p>{{ t('keepBuilding') }}</p>
              <a-progress type="circle" :percent="state.completeStatus"/>
            </div>

            <HelpSidebar/>

          </div>
        </div>
      </div>
    </div>
  </MemberLayout>
</template>

<script setup>
import {reactive, watch, onMounted} from 'vue'
import MemberLayout from './MemberLayout.vue'
import useGlobal from '../composables/useGlobal'
import Pricing from '../components/PublishApp/Pricing.vue'
import AppInfos from '../components/PublishApp/AppInfos.vue'
import DeveloperAccount from '../components/PublishApp/DeveloperAccount.vue'
import HelpSidebar from '../components/PublishApp/HelpSidebar.vue'
import createApp from '@shopify/app-bridge'
import {Redirect} from '@shopify/app-bridge/actions'
import {isShopifyEmbedded} from '@shopify/app-bridge-utils'

const {t, emitter, router} = useGlobal()

const state = reactive({
  activeTab: '1',
  // enableTab: 0,
  completeStatus: '0',
})

watch(() => state.activeTab, () => {
  emitter.emit('publish-app-active-tab', state.activeTab)
})


onMounted(() => {
  const scopes = 'unauthenticated_write_checkouts,unauthenticated_write_customers,unauthenticated_read_product_listings,unauthenticated_read_product_inventory,read_customers,read_orders,read_products'

  const API_KEY = 'c967f6425dcd0a0b92c8f0f160bd1dc6'

  const redirecUri = `https://${host}/api/auth?shop=${shop}&host=${host}&scopes=${scopes}`
  const permissionUrl = `https://${shop}/admin/oauth/authorize?client_id=${API_KEY}&scope=${scopes}&redirect_uri=https://${shop}/admin/apps/figmobi-3`

  console.log("redirecting to", permissionUrl)

  if (window.top === window.self) {
    window.location.assign(`https://${shop}/admin/apps/figmobi-3`)
  } else {

    Redirect.create(app).dispatch(Redirect.Action.REMOTE, permissionUrl);
  }

})


</script>


<style scoped>
.complete-status {
  background-color: #fff;
  display: grid;
  place-items: center;
  padding: 1em .5em;
  border-radius: 1em;
  margin-top: 4em;
}

</style>
