<template>
  <div class="card">
    <h2>Choose a plan that suits you best</h2>
    <p>
      We’ll provide industry definition, benefits, and relevant technologies for you to enter the world of m-commerce.
    </p>

    <h5>Publishing the Mobile Apps</h5>
    <p>
      Create Firebase, Google and Apple developer accounts, give access to Figmobi and fill this Publish App section.
    </p>

    <h5>Follow these steps to get your apps published:</h5>

    <h3>Step 1: <span>Create Firebase, Google and Apple developer accounts</span></h3>

    <ul>
      <li>
        FIREBASE: (Follow these steps)
      </li>
      <li>
        GOOGLE: (Follow these steps)
      </li>
      <li>
        APPLE: (Follow these steps)
      </li>
    </ul>


    <h3>Step 2: <span>Once enrolled, Grant us access to upload your apps to the play and app stores.</span></h3>

    <ol>
      <li>
        Granting Access: (Follow these steps)
      </li>
      <li>
        All Customers need to create their own Developer Accounts for Google and Apple
        <ol type="a">
          <li>
            Apple Charges USD 99 per year for an iOS Developer Account
          </li>
          <li>
            Google charges USD 25 as a one time lifetime cost for a Google Developer
          </li>
          <li>
            Apps uploaded to Google Play Stores and Apple App Stores are governed by their respective app store
            policies.
          </li>
          <li>
            All payments will be billed in US dollars.
          </li>
          <li>
            All custom development requests will be charged additional.
          </li>
        </ol>
      </li>
    </ol>

  </div>
</template>

<script setup>
import useGlobal from '../../composables/useGlobal'

const { t } = useGlobal()


</script>


<style scoped>


h3, h5 {
  margin-top: 1em;
  font-size: 1.2em;
}

h3 {
  font-size: 1.6em;
}
</style>
